import * as dateFns from 'date-fns'

export function parseTimeDiff(fromDate, toDate, { positiveOnly = true } = {}) {
  const diffSecs = dateFns.differenceInSeconds(toDate, fromDate)

  if (positiveOnly && diffSecs < 1) {
    return [0, 0, 0, 0] as const
  }

  const d = Math.trunc(diffSecs / 86400)
  const h = Math.trunc((diffSecs % 86400) / 3600)
  const m = Math.trunc((diffSecs % 3600) / 60)
  const s = diffSecs % 60
  return [d, h, m, s] as const
}
