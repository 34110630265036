import styled from 'styled-components'
import { defaultTheme, media } from '../../themes/theme'

const screens = defaultTheme.screens

export const Container = styled.div`
  width: 100%;
  ${media.sm`
  `}
  ${media.md`
    max-width: ${screens.md};
  `}
  ${media.lg`
    max-width: ${screens.lg};
  `}
  ${media.xl`
    max-width: ${screens.xl};
  `}
`
