import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../core/Section'

function Featured(props: {}) {
  const { t } = useTranslation()
  return (
    <>
      <Section title={t('featured')}>
        <></>
      </Section>
    </>
  )
}

export default Featured
