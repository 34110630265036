const baseHeading = (size, lineHeight) => ({
  fontSize: `${size}rem`,
  fontStyle: 'inherit',
  lineHeight: lineHeight / size,
})

export const textStyles = ({ colors, spacing }) => {
  // const c = darkMode ? colors.dark : colors.light
  const cHeading = 'inherit'
  const cSubtleHeading = 'inherit'

  // const grid = 8
  return {
    h900: {
      ...baseHeading(2.5, 2.85),
      color: cHeading,
      fontWeight: 500,
      letterSpacing: '-0.01em',
      // marginTop: `${grid * 6.5}px`,
    },
    h800: {
      ...baseHeading(2.25, 2.5),
      color: cHeading,
      fontWeight: 600,
      letterSpacing: '-0.01em',
      // marginTop: `${grid * 5}px`,
    },
    h700: {
      ...baseHeading(1.75, 2),
      color: cHeading,
      fontWeight: 500,
      letterSpacing: '-0.01em',
      // marginTop: `${grid * 5}px`,
    },
    h600: {
      ...baseHeading(1.5, 1.75),
      color: cHeading,
      fontWeight: 500,
      letterSpacing: '-0.008em',
      // marginTop: `${grid * 3.5}px`,
    },
    h500: {
      ...baseHeading(1.125, 1.5),
      color: cHeading,
      fontWeight: 600,
      letterSpacing: '-0.006em',
      // marginTop: `${grid * 3}px`,
    },
    h400: {
      ...baseHeading(1, 1.125),
      color: cHeading,
      fontWeight: 600,
      letterSpacing: '-0.003em',
      // marginTop: `${grid * 2}px`,
    },
    h300: {
      ...baseHeading(0.875, 1.125),
      color: cHeading,
      fontWeight: 600,
      // textTransform: 'uppercase',
      // marginTop: `${grid * 2.5}px`,
    },
    h200: {
      ...baseHeading(0.875, 1.125),
      color: cSubtleHeading,
      fontWeight: 600,
      // marginTop: `${grid * 2}px`,
    },
    h100: {
      ...baseHeading(0.75, 1.125),
      color: cSubtleHeading,
      fontWeight: 700,
      // marginTop: `${grid * 2}px`,
    },
  }
}
