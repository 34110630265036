import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import SlickSlider from 'react-slick'
import styled from 'styled-components'
import { defaultTheme, media } from '../../themes/theme'
import Section from '../core/Section'
import playArrow from './assets/play-arrow.png'

const query = gql`
  {
    video(first: 8) {
      youtubeId
      title
    }
  }
`

const { screens } = defaultTheme

const PlayArrowImg = styled.img`
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  ${media.md`
    width: 66px;
    height: 66px;
  `}
`

function VideoItem({ video }) {
  // const { data } = useRestQuery(
  //   ['youtubeVideo', { id: video.youtubeId }],
  //   youtubeVideoFetch
  // )
  return (
    <div className="text-white mx-4">
      <a
        className="cursor-pointer block hover:underline"
        href={`https://www.youtube.com/watch?v=${video.youtubeId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {
          // data &&
          <div className="relative pb-9/16 m-auto">
            <img
              // src={dlv(data, 'items.0.snippet.thumbnails.standard.url')}
              src={`https://i.ytimg.com/vi/${video.youtubeId}/sddefault.jpg`}
              className="absolute h-full w-full object-cover"
              alt=""
            />
            <PlayArrowImg src={playArrow} alt="" />
          </div>
        }
        <p className="mt-2">{video.title}</p>
      </a>
    </div>
  )
}

function Video(props: {}) {
  const { t } = useTranslation()
  const { data, loading } = useQuery(query)
  if (!data) return null
  return (
    <>
      <Section title={t('recentVideos')}>
        <div className="my-8 md:my-12">
          <SlickSlider
            dots={false}
            arrows={false}
            infinite={false}
            speed={800}
            slidesToScroll={1}
            swipeToSlide
            slidesToShow={4}
            responsive={[
              {
                breakpoint: Number.parseInt(screens.lg.slice(0, -2)),
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: Number.parseInt(screens.md.slice(0, -2)),
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: Number.parseInt(screens.sm.slice(0, -2)),
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {data.video.map((v, i) => (
              <VideoItem video={v} key={i} />
            ))}
          </SlickSlider>
        </div>
      </Section>
    </>
  )
}

export default Video
