import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
// import { withClientState } from 'apollo-link-state'
import { ApolloLink, Observable } from 'apollo-link'

const cache = new InMemoryCache({
  dataIdFromObject: (o: any) => o.contentItemId || o.id || null,
  cacheRedirects: {
    Query: {},
  },
})

const request = async (operation) => {
  // const token = await localStorage.getItem('token')
  // operation.setContext({
  //   headers: {
  //     authorization: token,
  //   },
  // })
}

const requestLink = () =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle
        Promise.resolve(operation)
          .then((oper) => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            })
          })
          .catch(observer.error.bind(observer))

        return () => {
          if (handle) handle.unsubscribe()
        }
      })
  )

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.error('GraphqlError:: ', graphQLErrors)
      }
      if (networkError) {
        console.error('NetworkError:: ', networkError)
      }
    }),
    requestLink(),
    // withClientState({
    //   defaults: {
    //     isConnected: true,
    //   },
    //   resolvers: {
    //     Mutation: {
    //       updateNetworkStatus: (_, { isConnected }, { cache }) => {
    //         cache.writeData({ data: { isConnected } })
    //         return null
    //       },
    //     },
    //   },
    //   cache,
    // }),
    new HttpLink({
      uri: window['GRAPHQL_ENDPOINT'],
      // credentials: 'include',
    }),
  ]),
  cache,
})
