import { useQuery } from '@apollo/react-hooks'
import dlv from 'dlv'
import gql from 'graphql-tag'
import * as React from 'react'
import styled from 'styled-components'
import { defaultTheme, media } from '../../themes/theme'
import { PageContainer } from '../core/Page'
import { allMatchesQuery } from '../queries/graphql'
import ScheduleAndUpcoming from './Schedule'

const VideoPlayer = React.lazy(() => import('./VideoPlayer'))

const LiveTitle = styled.p`
  ${(props) => props.theme.textStyles.h500};
  ${media.md`
    ${(props) => props.theme.textStyles.h800};
  `}
`

const { screens } = defaultTheme

const Frame = styled.iframe`
  width: 100vw;
  height: 56.25vw;
  ${media.md`
    width: ${screens.md};
    height: calc(${screens.md} * 9 / 16);
  `}
  ${media.lg`
    width: ${screens.lg};
    height: calc(${screens.lg} * 9 / 16);
  `}
  ${media.xl`
    width: ${screens.xl};
    height: calc(${screens.xl} * 9 / 16);
  `}
`

const DemoThumbnail = styled.div<{ bg: string }>`
  background: url('img/og_img.jpeg');
  background-size: cover;
`

const liveUrlQuery = gql`
  {
    liveVideo {
      embeddedUrl {
        text
        url
      }
      overlayImage {
        urls
      }
    }
  }
`

function Live(props: {}) {
  const { data: upcomingData, loading } = useQuery(allMatchesQuery, {
    variables: {
      params: allMatchesQuery.stringifyParams({
        limit: 4,
      }),
    },
  })
  const upcoming = (upcomingData && upcomingData.allMatches) || []

  // const { data: liveData } = useQuery(query, {
  //   variables: {
  //     params: JSON.stringify(
  //       JSON.stringify({
  //         fromDate: threeHoursBeforeStr,
  //         toDate: nowStr,
  //       })
  //     ),
  //   },
  // })
  // const live = (liveData && liveData.allMatches) || []

  const { data: urlData } = useQuery(liveUrlQuery)
  const [overlay, setOverlay] = React.useState(true)
  const liveVideoLength = dlv(urlData, 'liveVideo.length', 0)
  const overlayImage = dlv(urlData, 'liveVideo.0.overlayImage.urls.0')
  const url: string = dlv(urlData, 'liveVideo.0.embeddedUrl.url', '')
  const el = /(m3u8|mp4)$/.test(url) ? (
    <React.Suspense fallback={<div>Loading...</div>}>
      <VideoPlayer
        playerOptions={{
          fluid: true,
          sources: [
            {
              src: url,
            },
          ],
          loop: true,
          autoplay: true,
          controls: true,
          // html5: {
          //   hls: {
          //     withCredentials: true,
          //   },
          // },
        }}
      />
    </React.Suspense>
  ) : (
    <Frame
      width="560"
      height="315"
      // src="https://www.youtube-nocookie.com/embed/2D1BD6fiNg4"
      src={dlv(urlData, 'liveVideo.0.embeddedUrl.url')}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="embed-player"
    ></Frame>
  )

  const sliderProps = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToScroll: 1,
    swipeToSlide: true,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: Number.parseInt(screens.lg.slice(0, -2)),
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: Number.parseInt(screens.md.slice(0, -2)),
        settings: {
          slidesToShow: 2.5,
        },
      },
      {
        breakpoint: Number.parseInt(screens.sm.slice(0, -2)),
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  }

  return (
    <PageContainer wrapperClassName="text-white bg-black">
      <section className="">
        <div className="relative">
          {el}
          {overlay && overlayImage && (
            <div
              className="absolute h-full w-full top-0 left-0"
              onClick={() => setOverlay(false)}
            >
              <img
                src={
                  (window as any).CMS_BASE_URL +
                  dlv(urlData, 'liveVideo.0.overlayImage.urls.0')
                }
                alt=""
              />
            </div>
          )}
        </div>
        <footer className="text-white mt-8">
          <LiveTitle className="ml-4 md:ml-0">
            {dlv(urlData, 'liveVideo.0.embeddedUrl.text', '')}
          </LiveTitle>
        </footer>
      </section>
      <section className="pb-8 pt-6 md:pb-12 md:pt-10">
        {/* {live.length > 1 && <SlickSlider {...sliderProps}></SlickSlider>} */}
      </section>
      <div>
        <ScheduleAndUpcoming upcoming={upcoming}></ScheduleAndUpcoming>
      </div>
    </PageContainer>
  )
}

export default Live
