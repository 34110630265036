import * as React from 'react'
type Props = {}

function LeagueHighlights(props: Props) {
  return (
    <div>
      
    </div>
  )
}

export default LeagueHighlights