import React from 'react'
import { hot } from 'react-hot-loader/root'
import { ApolloProvider } from '@apollo/react-hooks'
// import './App.css'
import { client } from './providers/apollo'
import Theme from './providers/Theme'
import Root from './routes/Root'
import { createGlobalStyle } from 'styled-components'

import ReactGA from 'react-ga'

ReactGA.initialize('UA-155241843-1')
ReactGA.pageview(window.location.pathname + window.location.search)

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Big Shoulders Text;
  }
`

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <Theme>
        <GlobalStyle></GlobalStyle>
        <Root />
      </Theme>
    </ApolloProvider>
  )
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
