import i18n from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    resources: {
      en: {
        translation: require('./langs/en/translation.json')
      },
      vi: {
        translation: require('./langs/vi/translation.json')
      },
    },
    // lng: 'en',
    fallbackLng: ['en', 'vi'],
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['localStorage', 'cookie'],
    },
  })

export default i18n
