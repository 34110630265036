import * as React from 'react'
import { Outlet } from 'react-router-dom'

type Props = {}

function League(props: Props) {
  return (
    <div>
      <Outlet></Outlet>
    </div>
  )
}

export default League
