import { css } from 'styled-components'
import tw from './tailwind'
import { textStyles } from './typography'

export const themeCreator = (darkMode?: boolean) => ({
  zIndices: tw.theme.zIndex,
  colors: {
    ...tw.theme.colors,

    navBarBG: '#0E0E0E',
    footerBG: '#0E0E0E',

    overlay: {
      500: 'rgba(0, 0, 0, 0.5)',
      800: 'rgba(0, 0, 0, 0.8)',
    },
  },
  shadows: tw.theme.boxShadow,
  fontSizes: tw.theme.fontSize,
  textStyles: textStyles({
    colors: tw.theme.colors,
    spacing: tw.theme.spacing,
  }),
  spacing: tw.theme.spacing,
  screens: tw.theme.screens,
})

export const defaultTheme = themeCreator(true)

export type Theme = typeof defaultTheme

export const media = Object.keys(defaultTheme.screens).reduce((acc, label) => {
  acc[label] = function() {
    return css`
      @media (min-width: ${defaultTheme.screens[label]}) {
        ${css.apply(null, arguments as any)}
      }
    `
  }

  return acc
}, {}) as {
  sm: (...args: any[]) => string
  md: (...args: any[]) => string
  lg: (...args: any[]) => string
  xl: (...args: any[]) => string
}
