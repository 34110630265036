import * as React from 'react'
import { Link } from 'react-router-dom'
type Props = {}

function NotFound(props: Props) {
  return (
    <div className="py-8 text-center bg-black">
      <p className="text-white mb-4">404 Not found</p>
      <div>
        <Link
          to="/"
          className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Back to home
        </Link>
      </div>
    </div>
  )
}

export default NotFound
