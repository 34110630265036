import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IoLogoInstagram } from 'react-icons/io'
import { FaFacebookF } from 'react-icons/fa'

type Props = {}

const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.footerBG};
  padding-top: 45px;
  padding-bottom: 45px;
  ${(props) => props.theme.textStyles.h500};
`

const Links = styled.div`
  color: rgba(255, 255, 255, 0.5);
`

function Footer(props: Props) {
  const year = new Date().getFullYear()
  const { t } = useTranslation()
  return (
    <Wrapper>
      <div className="flex items-center text-center flex-col justify-between px-4 py-3 sm:p-0">
        <div>
          <img className="h-8" src="/img/logo@2x.png" alt="Courtside" />
        </div>
        <p className="text-white mt-8">
          Copyright © {year} Courtside VN. All rights reserved.
        </p>
        <p className="text-white mt-2">
          The NBA 2020 is brought to you by <a href="xle.vn" className="underline">XLE Group</a>.
        </p>
        {/* <Links className="mt-5">
          <a href="">{t('Privacy Policy')}</a> |&nbsp;
          <a href="">{t('Accessibility and Closed Caption')}</a> |&nbsp;
          <a href="">{t('Terms of Use')}</a> |&nbsp;
          <a href="">{t('AdChoices')}</a>
        </Links>
        <p className="mt-8">
          <a href="">
            <IoLogoInstagram
              fill="white"
              className="inline text-3xl mr-4"
            ></IoLogoInstagram>
          </a>
          <a href="">
            <FaFacebookF fill="white" className="inline text-xl"></FaFacebookF>
          </a>
        </p> */}
      </div>
    </Wrapper>
  )
}

export default Footer
