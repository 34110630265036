import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../core/Section'
import dlv from 'dlv'
import {
  ResText400,
  ResText600,
  Text600,
  ResText800,
  ResText500,
  Text800,
  MonospaceChar,
} from '../core/Text'

import * as dateFns from 'date-fns'

import styled from 'styled-components'

import upcomingBg1 from './assets/upcoming-bg-1.jpg'
import upcomingBg2 from './assets/upcoming-bg-2.jpg'
import upcomingBg3 from './assets/upcoming-bg-3.jpg'
import upcomingBg4 from './assets/upcoming-bg-4.jpg'
import { media } from '../../themes/theme'
import { BackgroundBlock } from '../core/Block'
import { FormatUtils } from '../../utils'
import { useSecondTick } from '../core/hooks'
import { AbsoluteCenter } from '../core/Layout'

const TimeUnit = styled.p`
  ${(props) => props.theme.textStyles.h500}
  color: #fff;
  opacity: 0.5;
  text-align: center;
  width: 40px;
  padding-left: 2px;
  ${media.md`
    width: 80px;
  `}
  font-weight: initial;
`

const GroupCounter = styled.section`
  font-size: 3rem;
  line-height: 1.15;
  & > :nth-child(n + 2) {
    margin-left: 14px;
  }
  ${media.md`
    margin-left: 16px
    font-size: 6rem;

    & > :nth-child(n + 2) {
      margin-left: 20px;
    }
  `}
`

function Counter({ toDate: _toDate }) {
  const { t } = useTranslation()
  const toDate = React.useMemo(() => new Date(_toDate), [_toDate])
  useSecondTick()

  const [d, h, m, s] = FormatUtils.parseTimeDiff(new Date(), toDate, {
    positiveOnly: true,
  })

  if (d === 0 && h === 0 && m === 0 && s === 0) {
    return (
      <div>
        <Text800
          className="uppercase m-auto text-center"
          style={{ color: '#C11D2A' }}
        >
          {t('live')}
        </Text800>
      </div>
    )
  }

  const charFontSize = { width: 20, mdWidth: 40 }

  return (
    <div>
      <ResText800
        bold={false}
        className="uppercase m-auto text-center opacity-50 font-normal"
      >
        {t('upcoming')}
      </ResText800>
      <GroupCounter className="flex items-center font-bold">
        {d > 0 && (
          <div>
            <p>
              <MonospaceChar
                {...charFontSize}
                text={d.toString().padStart(2, '0')}
              ></MonospaceChar>
              {' : '}
            </p>
            <TimeUnit className="capitalize">{t('day', { count: d })}</TimeUnit>
          </div>
        )}

        <div>
          <p className="">
            <MonospaceChar
              {...charFontSize}
              text={h.toString().padStart(2, '0')}
            ></MonospaceChar>
            {' : '}
          </p>
          <TimeUnit className="capitalize">{t('hour', { count: h })}</TimeUnit>
        </div>
        <div>
          <p className="">
            <MonospaceChar
              {...charFontSize}
              text={m.toString().padStart(2, '0')}
            ></MonospaceChar>
            {' : '}
          </p>
          <TimeUnit className="capitalize">
            {t('minute', { count: m })}
          </TimeUnit>
        </div>
        <div>
          <p className="">
            <MonospaceChar
              {...charFontSize}
              text={s.toString().padStart(2, '0')}
            ></MonospaceChar>
          </p>
          <TimeUnit className="capitalize">
            {t('second', { count: s })}
          </TimeUnit>
        </div>
      </GroupCounter>
    </div>
  )
}

const LeagueLogo = styled.img`
  height: 2.5rem;
  width: auto;
  position: absolute;
  top: 1rem;
  right: 1rem;
`

function UpcomingContent({ item }) {
  const imageUrl1 = dlv(item, 'team1.contentItems.0.logo.urls.0')
  const imageUrl2 = dlv(item, 'team2.contentItems.0.logo.urls.0')
  const stadium = dlv(item, 'stadium.contentItems.0.name')
  const leagueImageUrl = dlv(item, 'league.contentItems.0.logo.urls.0')
  const leagueName = dlv(item, 'league.contentItems.0.name')
  return (
    <div className="text-white relative">
      <BackgroundBlock
        bg={upcomingBg1}
        className="flex flex-col lg:flex-row items-center justify-center mt-12 py-10 xl:py-16 bg-right-bottom"
      >
        <div>
          {leagueImageUrl && (
            <LeagueLogo
              src={(window as any).CMS_BASE_URL + leagueImageUrl}
              alt={leagueName}
            />
          )}
        </div>
        <div className="flex items-center">
          <div>
            {imageUrl1 && (
              <img
                className="w-24 h-24 md:w-40 md:h-40 object-contain"
                src={(window as any).CMS_BASE_URL + imageUrl1}
                alt=""
              />
            )}
          </div>
          <Text600 className="mx-8 md:mx-12">vs</Text600>
          <div>
            {imageUrl2 && (
              <img
                className="w-24 h-24 md:w-40 md:h-40 object-contain"
                src={(window as any).CMS_BASE_URL + imageUrl2}
                alt=""
              />
            )}
          </div>
        </div>
        <div
          className="mt-16 md:mt-0 md:ml-12 text-center"
          style={{ minWidth: 60 }}
        >
          {item.dateTime && <Counter toDate={item.dateTime}></Counter>}
          {stadium && <ResText800 className="mt-4">@{stadium}</ResText800>}
        </div>
      </BackgroundBlock>
    </div>
  )
}

function UpcomingContentSmall({ item, bg }) {
  const name1 = dlv(item, 'team1.contentItems.0.shortname')
  const name2 = dlv(item, 'team2.contentItems.0.shortname')
  const stadium = dlv(item, 'stadium.contentItems.0.name')
  const leagueImageUrl = dlv(item, 'league.contentItems.0.logo.urls.0')
  const leagueName = dlv(item, 'league.contentItems.0.name')

  return (
    <div className="text-white flex-1 md:mx-1 mt-9 md:mt-0 relative">
      <BackgroundBlock bg={bg} className="relative h-full pb-9/16">
        {leagueImageUrl && (
          <LeagueLogo
            src={(window as any).CMS_BASE_URL + leagueImageUrl}
            alt={leagueName}
          />
        )}
        <AbsoluteCenter>
          <div className="font-semibold">
            <p className="text-sm md:text-lg opacity-50">
              {dateFns.format(new Date(item.dateTime), 'EEE, MMM dd - hh:mma')}
            </p>
          </div>
          <div className="flex items-end text-5xl leading-none font-semibold my-2">
            <p className="uppercase align-bottom">{name1}</p>
            <p className="align-bottom mx-3">vs</p>
            <p className="uppercase align-bottom">{name2}</p>
          </div>
          <div className="text-center font-semibold">
            {stadium && (
              <p className="text-xs md:text-sm opacity-50">@{stadium}</p>
            )}
          </div>
        </AbsoluteCenter>
      </BackgroundBlock>
    </div>
  )
}

function ScheduleAndUpcoming({ upcoming }) {
  const { t } = useTranslation()

  if (!upcoming || upcoming.length === 0) return null
  const [first, ...rest] = upcoming
  return (
    <>
      <Section reverseColor title={t('upcoming')}>
        <UpcomingContent item={first} />
        <div className="flex flex-col lg:flex-row md:-mx-1 mb-9 md:mt-2">
          {rest.map((item, i) => (
            <UpcomingContentSmall
              item={item}
              bg={i === 0 ? upcomingBg2 : i === 1 ? upcomingBg3 : upcomingBg4}
            />
          ))}
        </div>
      </Section>
    </>
  )
}

export default ScheduleAndUpcoming
