import * as React from 'react'
import styled from 'styled-components'
import { media } from '../../themes/theme'

export const Text400 = styled.p`
  ${props => props.theme.textStyles.h400}
`
export const Text500 = styled.p`
  ${props => props.theme.textStyles.h500}
`
export const Text600 = styled.p`
  ${props => props.theme.textStyles.h600}
`
export const Text700 = styled.p`
  ${props => props.theme.textStyles.h700}
`
export const Text800 = styled.p`
  ${props => props.theme.textStyles.h800}
`

export const ResText400 = styled.p`
  ${props => props.theme.textStyles.h300}
  ${media.md`
    ${props => props.theme.textStyles.h400}
  `}
`
export const ResText500 = styled.p`
  ${props => props.theme.textStyles.h400}
  ${media.md`
    ${props => props.theme.textStyles.h500}
  `}
`
export const ResText600 = styled.p`
  ${props => props.theme.textStyles.h400}
  ${media.md`
    ${props => props.theme.textStyles.h600}
  `}
`

export const ResText800 = styled.p<{ bold?: boolean }>`
  ${props => props.theme.textStyles.h600}
  ${media.md`
    ${props => props.theme.textStyles.h800}
  `}
  ${props => props.bold === false && 'font-weight: initial !important;'}
`

const Char = styled.span<{ width: number; mdWidth: number }>`
  display: inline-block;
  text-align: center;
  width: ${props => props.width}px;
  ${media.md`
    width: ${props => props.mdWidth}px;
  `}
`

export function MonospaceChar({
  text,
  width,
  mdWidth,
}: {
  text: string
  width: number
  mdWidth: number
}) {
  const mapChar = (c, i) => (
    <Char width={width} mdWidth={mdWidth} key={i}>
      {c}
    </Char>
  )
  return <span>{text.split('').map(mapChar)}</span>
}
