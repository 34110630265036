import * as React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import Footer from '../pkgs/core/Footer'
import Navbar from '../pkgs/core/Navbar'
import NotFound from '../pkgs/core/NotFound'
import Home from '../pkgs/home/Home'
import League from '../pkgs/league/League'
import LeagueHighlights from '../pkgs/league/LeagueHighlights'
import LeagueHome from '../pkgs/league/LeagueHome'
import LeagueReplays from '../pkgs/league/LeagueReplays'
import LeagueSchedule from '../pkgs/league/LeagueSchedule'
import Live from '../pkgs/live/Live'

function Root() {
  return (
    <Router>
      <Navbar></Navbar>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/live" replace></Navigate>}
        ></Route>
        <Route path="/live" element={<Live />} />
        <Route path="/league/:leagueId" element={<League />}>
          <Route path="/" element={<LeagueHome />}></Route>
          <Route path="/replays" element={<LeagueReplays />}></Route>
          <Route path="/highlights" element={<LeagueHighlights />}></Route>
          <Route path="/schedule" element={<LeagueSchedule />}></Route>
        </Route>
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
      <Footer></Footer>
    </Router>
  )
}

export default Root
