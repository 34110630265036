import * as dateFns from 'date-fns'
import dlv from 'dlv'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../core/Section'
import { ResText400, ResText500, ResText600 } from '../core/Text'
import { useQuery } from '@apollo/react-hooks'
import { leagueQuery, allMatchesQuery } from '../queries/graphql'
import after from 'lodash/after'
import { useNavigate } from 'react-router-dom'

import { IoIosOpen } from 'react-icons/io'

function ScheduleContent({
  league,
  onEmpty,
}: {
  league: { contentItemId: string }
  onEmpty: () => void
}) {
  const { t } = useTranslation()
  const initialLimit = 3
  const totalLimit = 10
  const navigate = useNavigate()
  const [limit, setLimit] = React.useState(initialLimit)

  const { data, loading } = useQuery(allMatchesQuery, {
    variables: {
      params: allMatchesQuery.stringifyParams({
        fromDate: new Date(0),
        leagueId: league.contentItemId,
        limit,
      }),
    },
    onCompleted: (data) => {
      if (data.allMatches.length === 0) {
        onEmpty()
      }
    },
  })
  const prevDataRef = React.useRef([])
  if (
    (!data || data.allMatches.length === 0) &&
    prevDataRef.current.length === 0
  )
    return null
  const renderMatches = (prevDataRef.current =
    (data && data.allMatches.length > 0 && data.allMatches) ||
    prevDataRef.current)
  return (
    <div>
      <ul className="pb-12">
        {renderMatches.map((match, i) => {
          const date = match.dateTime && new Date(match.dateTime)
          let dateString = ''
          if (date) {
            if (date.getMinutes() === 0) {
              dateString = dateFns.isToday(date)
                ? dateFns.format(date, 'hha')
                : dateFns.format(date, 'hha MMM dd')
            } else {
              dateString = dateFns.isToday(date)
                ? dateFns.format(date, 'hh:mma')
                : dateFns.format(date, 'hh:mma MMM dd')
            }
          }
          const imageUrl = dlv(match, 'league.contentItems.0.logo.urls.0')
          const stadium = dlv(match, 'stadium.contentItems.0.name')
          return (
            <li
              className="flex items-center justify-between mt-10 md:mt-12 px-4"
              key={i}
            >
              <div className="md:flex items-center w-3/12">
                {imageUrl && (
                  <img
                    className="object-contain w-10 h-10 mb-3 md:mb-0 md:mr-12 md:w-16 md:h-16"
                    src={(window as any).CMS_BASE_URL + imageUrl}
                    alt=""
                  />
                )}
                <div>
                  <ResText500>{dateString}</ResText500>
                  {stadium && (
                    <ResText400 className="opacity-50 mt-1 md:mt-3 normal-case">
                      @{stadium}
                    </ResText400>
                  )}
                </div>
              </div>
              <div className="w-7/12 flex flex-col md:flex-row items-center">
                {/* <div className="flex items-center justify-center"> */}
                <ResText600 className="md:w-5/12 text-center md:text-right">
                  {dlv(match, 'team1.contentItems.0.name')}
                </ResText600>
                <ResText500 className="md:w-2/12 text-center md:mx-16 whitespace-no-wrap opacity-50 md:opacity-100 my-2">
                  - <span className="mx-4 md:mx-6">vs.</span> -
                </ResText500>
                <ResText600 className="md:w-5/12 text-center md:text-left">
                  {dlv(match, 'team2.contentItems.0.name')}
                </ResText600>
                {/* </div> */}
              </div>
              <div></div>
            </li>
          )
        })}
      </ul>
      <div>
        {limit === initialLimit ? (
          <button
            key={0}
            onClick={() => {
              return setLimit(totalLimit)
            }}
            className="uppercase m-auto border-b border-black block text-2xl"
          >
            {t('more')}
          </button>
        ) : (
          <button
            key={1}
            onClick={() => {
              navigate(`/leagues/${league.contentItemId}`)
            }}
            className="uppercase m-auto border-b border-black block text-2xl"
          >
            {t('more')}{' '}
            <span className="inline-block align-middle">
              <IoIosOpen></IoIosOpen>
            </span>
          </button>
        )}
      </div>
    </div>
  )
}

function Upcoming(props: {}) {
  const { data: leagueData, loading } = useQuery(leagueQuery)
  const { t } = useTranslation()
  const [empty, setEmpty] = React.useState(false)

  if (empty || !leagueData || !leagueData.league) return null
  const onLeagueEmpty = after(Math.min(leagueData.league.length, 1), () => {
    setEmpty(true)
  })
  return (
    <Section title={t('upcomingSchedule')}>
      <Section title={t('schedule')}>
        {leagueData.league.map((league) => (
          <ScheduleContent league={league} onEmpty={onLeagueEmpty} />
        ))}
      </Section>
    </Section>
  )
}

export default Upcoming
