import { useQuery } from '@apollo/react-hooks'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown, IoIosRadio } from 'react-icons/io'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { allLeaguesQuery } from '../queries/graphql'
import { Container } from './Grid'
type Props = {}

const Header = styled.header`
  background: ${(props) => props.theme.colors.navBarBG};
  height: 80px;
`

const StyledLink = styled(Link)`
  ${(props) => props.theme.textStyles.h600};
  color: #fff;
`

const StyledSelect = styled.select`
  ${(props) => props.theme.textStyles.h600};
`

function Navbar(props: Props) {
  const { data, loading } = useQuery(allLeaguesQuery)
  const { t, i18n } = useTranslation()

  const linkClassNames =
    'text-white no-underline uppercase leading-none whitespace-no-wrap mr-12'

  const leaguesNav =
    (data &&
      data.league &&
      data.league.map((l) => (
        <StyledLink
          to={`/league/${l.contentItemId}`}
          className={linkClassNames}
        >
          <span className="align-middle">{l.navMenuItem}</span>
        </StyledLink>
      ))) ||
    []
  return (
    <Header>
      <Container className="mx-auto h-full justify-between items-center sm:px-4 sm:py-3 text-white flex align-center">
        <div className="flex items-center justify-between px-4 py-3 sm:p-0">
          <div>
            <img className="h-8" src="/img/logo@2x.png" alt="Courtside" />
          </div>
          {/* <div className="sm:hidden">
            <button
              type="button"
              className="block text-gray-500 hover:text-white focus:text-white focus:outline-none"
            ></button>
          </div> */}
        </div>
        <nav className="px-2 pt-2 pb-4 flex sm:p-0 items-center">
          {/* <StyledLink to="#" className={linkClassNames}>
            {t('recentVideos')}
          </StyledLink>
          <StyledLink to="#" className={linkClassNames}>
            {t('upcoming')}
          </StyledLink>
          <StyledLink to="#" className={linkClassNames}>
            {t('schedule')}
          </StyledLink> */}

          <StyledLink
            to="#"
            className={linkClassNames}
            style={{ color: '#C11D2A' }}
          >
            <IoIosRadio className="inline text-sm"></IoIosRadio>{' '}
            <span className="align-middle">{t('live')}</span>
          </StyledLink>
          {leaguesNav}
          {/* <span className="mt-1 block px-2 py-1 text-red font-semibold rounded hover:bg-gray-800 sm:mt-0 sm:ml-2">
          {t('live')}
        </span> */}
          <label className="flex items-center relative">
            <StyledSelect
              value={
                i18n.language.includes('-')
                  ? i18n.language.split('-')[0]
                  : i18n.language
              }
              onChange={(e) => i18n.changeLanguage(e.target.value)}
              className="appearance-none w-full py-1 px-2 rounded leading-tight focus:outline-none focus:shadow-outline pr-5 bg-transparent"
            >
              <option value="vi">VI</option>
              <option value="en">EN</option>
            </StyledSelect>
            <span
              className="absolute top-auto pointer-events-none"
              style={{ right: 3 }}
            >
              <IoIosArrowDown></IoIosArrowDown>
            </span>
          </label>
        </nav>
      </Container>
    </Header>
  )
}

export default Navbar
