import * as React from 'react'
import { PageContainer } from '../core/Page'
import Video from './Video'
import Shows from './Shows'
import Upcoming from './Upcoming'
import Featured from './Featured'
type Props = {}

function Home(props: Props) {
  return (
    <PageContainer className="">
      <Video></Video>
      <Shows></Shows>
      <Upcoming></Upcoming>
      <Featured></Featured>
    </PageContainer>
  )
}

export default Home
