import * as React from 'react'
import { Container } from './Grid'

export function PageContainer(props: any) {
  return (
    <div className={`w-full ${props.wrapperClassName || ''}`}>
      <Container
        {...props}
        className={`mx-auto ${props.className || ''}`}
      ></Container>
    </div>
  )
}
