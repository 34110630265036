import * as React from 'react'
import Section from '../core/Section'
import { useTranslation } from 'react-i18next'
type Props = {}

function LeagueHome(props: Props) {
  const { t } = useTranslation()
  return (
    <div>
      <Section title={t('replays')}>
        <></>
      </Section>
      <Section title={t('highlights')}>
        <></>
      </Section>
      <Section title={t('schedule')}>
        <></>
      </Section>
    </div>
  )
}

export default LeagueHome
