import gql from 'graphql-tag'
import padStart from 'lodash/padStart'
import { DocumentNode } from 'graphql'

export const leagueQuery = gql`
  query LeagueQuery {
    league {
      contentItemId
      name
      logo {
        urls
      }
    }
  }
`

function getDateQS(d: Date) {
  return (
    d.getUTCFullYear().toString() +
    padStart((d.getUTCMonth() + 1).toString(), 2, '0') +
    padStart(d.getUTCDate().toString(), 2, '0') +
    padStart(d.getUTCHours().toString(), 2, '0') +
    padStart(d.getUTCMinutes().toString(), 2, '0') +
    '00'
  )
}

function stringifyParams(params: {
  fromDate?
  toDate?
  skip?
  limit?
  leagueId?
}) {
  if (!('fromDate' in params)) {
    params.fromDate = getDateQS(new Date())
  }
  return JSON.stringify(params)
}

export const allMatchesQuery: DocumentNode & {
  stringifyParams: typeof stringifyParams
} = gql`
  query AllMatchesQuery($params: String!) {
    allMatches(parameters: $params) {
      contentItemId
      dateTime
      league {
        contentItems {
          ... on League {
            name
            logo {
              urls
            }
          }
        }
      }
      stadium {
        contentItems {
          ... on Stadium {
            name
          }
        }
      }
      team1 {
        contentItems {
          ... on Team {
            name
            shortname
            logo {
              urls
            }
          }
        }
      }
      team1Score
      team2 {
        contentItems {
          ... on Team {
            name
            shortname
            logo {
              urls
            }
          }
        }
      }
      team2Score
    }
  }
`
allMatchesQuery.stringifyParams = stringifyParams

export const allLeaguesQuery = gql`
  query AllLeaguesQuery {
    league {
      contentItemId
      name
      navMenuItem
      path
    }
  }
`
