import React from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme, themeCreator } from '../themes/theme'

// import { IconContext } from 'react-icons'

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    /* <IconContext.Provider
        value={{ className: 'inline-block align-text-top' }}
      >
      </IconContext.Provider> */
  )
}

export default Theme
