import * as React from 'react'
import styled from 'styled-components'
import { media } from '../../themes/theme'

const Title = styled.h3`
  ${(props) => props.theme.textStyles.h500}
  line-height: 26px;
`

const TitleWrapper = styled.div`
  width: 50%
  max-width: 80%;
  ${media.md`
    width: 316px;
  `}
  flex: 0 1 auto;
`

const Doughnut = styled.div`
  position: absolute;
  right: -14px;
  top: 4px;
  height: 34px;
  width: 34px;
  border-width: 8px;
  border-style: solid;
  border-radius: 100px;
`

function SectionHeader({
  title,
  reverseColor,
}: {
  title: string
  reverseColor?: boolean
}) {
  return (
    <header
      className={`flex items-center ${
        reverseColor ? 'text-black' : 'text-white'
      }`}
    >
      <TitleWrapper
        className={`px-4 py-2 relative overflow-hidden ${
          reverseColor ? 'bg-white' : 'bg-black'
        }`}
      >
        <Title
          className={`uppercase ${reverseColor ? 'text-black' : 'text-white'}`}
        >
          {title}
        </Title>
        <Doughnut
          className={`uppercase ${
            reverseColor ? 'border-black bg-white' : 'border-white bg-black'
          }`}
        ></Doughnut>
      </TitleWrapper>
      <div className="flex-1">
        <hr className={`h-px m-0 ${reverseColor ? 'bg-white' : 'bg-black'}`} />
      </div>
    </header>
  )
}

function Section({
  title,
  children,
  reverseColor,
}: {
  title: string
  children: React.ReactNode
  reverseColor?: boolean
}) {
  return (
    <section>
      <SectionHeader title={title} reverseColor={reverseColor}></SectionHeader>
      {children}
    </section>
  )
}

export default Section
